html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bg-primary-dark {
  background: #2c2e3e !important;
  color: #fff !important;
}

.container-fluid {
  padding: 0;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}
