.dark {
  & > div {
    background: rgba(255, 255, 255, 0.91);

    &:hover {
      background: rgba(255, 255, 255, 0.87);
    }

    &.Mui-focused {
      background: rgba(255, 255, 255, 0.91);
    }
  }
}
