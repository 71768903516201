.avatar {
  min-width: 100px;
  min-height: 100px;
}

.tabs {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.6);
}

.tab-group {
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.head-text {
  margin: 0;
}

.head-text,
.sub-head-text {
  text-transform: uppercase;
}

.def-list {
  .record {
    display: flex;

    .key {
      display: inline-block;
      flex: none;
      width: 140px;
      font-weight: bold;
      text-align: right;
      color: rgba(0, 0, 0, 0.64);
      padding-right: 10px;
    }

    .val {
      word-break: break-word;
    }
  }
}
